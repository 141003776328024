angular.module('MyHippoProducer.Controllers').directive('formTable',  function () {
    return {
        restrict: 'A',
        scope: {
            section: '<',
            readonly: '<',
            isDisabled: '<',
            tooltip: '<',
            parentKey: '<',
            page: '<',
            formatOverride: '@',
            colspan: '<',
        },
        templateUrl: 'components/form/table/table.html',
        controller: function ($scope, PolicyService, WorkflowAdapter) {
            const { page } = $scope;
            $scope.wfSection = WorkflowAdapter.getSection(page, $scope.section);
            const { properties: { parent_key: parentKey } } = $scope.wfSection;
            $scope.headers = WorkflowAdapter.getAllFieldsForSection(page, $scope.section).filter(
                (f) => f.label && f.visible !== false
            );

            // Assumption: Grids always have a parentKey
            if (parentKey) {
                $scope.values = $scope.wfSection.items;
            }

            // Change format to string if values are provided
            if ($scope.values) {
                $scope.values.forEach(item => {
                    const fields = Object.keys(item);
                    fields.forEach(field => {
                        if (item[field].values && item[field].type === 'boolean') {
                            item[field].formatOverride = 'string';
                        }
                    });
                });
            }
        }
    };
});
